import Link from 'next/link'
import Head from 'next/head';
import Image from 'next/image';
import styles from '../styles/sass/pages/404.module.scss'
import useWindowSize from '../util/useWindowSize'

export default function FourOhFour() {
  const size = useWindowSize();
  return (
    <>
      <Head>
        <title>Szabó Jennifer Pszichológus | A keresett oldal nem található</title>
        <meta name="description" content="Szabó Jennifer Pszichológus" />
      </Head>
      <main /* className='h-full' */>
        <div className={styles.fourOhFourHeroContainer}>
          <div className={styles.fourOhFourHero}>
            <div className={styles.fourOhFourImageSide}>
              <div className={styles.fourOhFourImageContainer}>
                <Image
                  src="/img/404-2.jpeg"
                  className={styles.heroImg}
                  width={800}
                  height={550}
                  alt="Nyitott könyv üres lapokkal"
                />
                <div className={styles.fourOhFourHeadingSide}>
                  <h1 className={styles.fourOhFourHeading}>
                    A KERESETT OLDAL NEM TALÁLHATÓ
                  </h1>
                  <div className={styles.fourOhFourButtonContainer}>
                    <Link href='/' passHref legacyBehavior>
                      <a className={styles.btnSecondary}>
                        <span>FŐOLDAL</span>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>)
}